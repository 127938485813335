import React from "react";
import FormOne from "../contact/FormOne";
import { Link } from "react-router-dom";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle" style={{ fontSize: "50px" }}>
                  About Us
                </span>
                <h2
                  className="title mb--30"
                  style={{ fontSize: "20px", color: "gray" }}
                >
                  We Design user-centric websites &amp; data-driven marketing
                  campaigns
                </h2>
                <p>
                  At DynoGrafx Solution, we are Nashik's premier digital
                  marketing and website development company, dedicated to
                  propelling local businesses into the digital spotlight. Our
                  passion lies in designing user-centric websites and crafting
                  data-driven marketing campaigns that deliver tangible results.{" "}
                </p>
                <p>
                  We believe that every business deserves a website that truly
                  represents its unique identity and meets its specific goals.
                  That's why we approach each project with fresh eyes,
                  developing custom solutions that prioritize user experience
                  and drive conversions. Our websites are more than just digital
                  brochures – they're powerful tools designed to engage visitors
                  and turn them into loyal customers.{" "}
                </p>
                <Link
                  to={process.env.PUBLIC_URL + "/about-us"}
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Know More
                </Link>

                <div className="case-study-counterup">
                  <div className="single-counterup">
                    <h2 className="count-number">
                      <TrackVisibility once>
                        {({ isVisible }) => (
                          <span className="number count">
                            {isVisible ? (
                              <CountUp end="1000" duration={1} />
                            ) : null}
                          </span>
                        )}
                      </TrackVisibility>
                      <span className="symbol">+</span>
                    </h2>
                    <span className="counter-title">Happy Clients</span>
                  </div>
                  <div className="single-counterup">
                    <h2 className="count-number">
                      <TrackVisibility once>
                        {({ isVisible }) => (
                          <span className="number count">
                            {isVisible ? (
                              <CountUp end="100" duration={1} />
                            ) : null}
                          </span>
                        )}
                      </TrackVisibility>
                      <span className="symbol">+</span>
                    </h2>
                    <span className="counter-title">Projects delivered</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Contact Us Now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
