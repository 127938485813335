import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Local Experties",
    para: "we understand the local market dynamics and can tailor strategies to suit regional needs.",
  },
  {
    id: 2,
    title: "Customized Approach",
    para: "We don't believe in one-size-fits-all solutions. Each project is approached with fresh perspective and tailored to meet specific business goals.",
  },
  {
    id: 3,
    title: "Experienced Team",
    para: " Our professionals bring years of industry experience and stay updated with the latest digital trends and technologies.",
  },
  {
    id: 4,
    title: "Transparent Communcation",
    para: "We believe in building long-term relationships with our clients through open, honest communication and regular progress updates.",
  },
  {
    id: 5,
    title: "Result - Oriented",
    para: "Our focus is on delivering measurable results that contribute to your business growth and ROI.",
  },
  {
    id: 6,
    title: "Continuous Support",
    para: "We offer ongoing support and maintenance to ensure your digital assets perform optimally long after the initial project is completed."
  }
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Our Valus"
          title="Why should you work with us?"
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
