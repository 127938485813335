import React from 'react';
import GraphicPropOne from './itemProp/GraphicPropOne';
import GraphicData from "../../data/project/GraphicData.json";

const AllData = GraphicData;

const GraphicFive = ({colSize, parentClass}) => {
    const projects = AllData.slice(0, 16);

    const displayProjects = projects.map((data) => (
        <div className="col" key={data.id}>
            <GraphicPropOne projectStyle="" portfolio={data}/>
        </div>
    ))

    return (
        <div className={`section section-padding-equal pt--200 pt_md--80 pt_sm--60 ${parentClass ? parentClass : ""}`}>
            <div className="container-fluid plr--30">
                <div className={`row row-cols-1 ${colSize ? colSize : "row-cols-sm-2 row-cols-xl-3"}`}>
                    {displayProjects}
                </div>
            </div>
        </div>
    )
}

export default GraphicFive;