import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h2 className="title">
                  Crafting digital solutions for visionary businesses
                </h2>
                <p>
                  DynoGrafX Solutions stands at the forefront of digital
                  innovation in Nashik, offering unparalleled expertise in
                  digital marketing and web development. Since our inception, we
                  have been driven by a singular mission: to empower businesses
                  with cutting-edge digital solutions that drive growth, enhance
                  visibility, and deliver measurable results.
                </p>
                <h4 className="sub-title" style={{ color: "gray" }}>
                  Our Expertise
                </h4>
                <li className="list-item">
                  Custom website design and development
                </li>
                <li className="">Search engine optimization (SEO)</li>
                <li className="">Social media marketing</li>
                <li className="">Content creation and management</li>
                <li className=""> E-commerce solutions</li>
                <li className=""> Mobile app development</li>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="100" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Happy Clients</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp end="100" duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
