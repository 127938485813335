import React from 'react';


const SpcialServicesPropOne = ({ projectStyle, portfolio }) => {
    const handleDownload = (e) => {
        e.preventDefault();
        
        // Assuming portfolio.filePath contains the path to the downloadable file
        if (portfolio.filePath) {
            const link = document.createElement('a');
            link.href = process.env.PUBLIC_URL + portfolio.filePath;
            link.download = portfolio.title; // Or any other name you prefer
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No file path provided for download');
        }
    };

    return (
        <>
            <div className={`project-grid ${projectStyle}`}>
                <div className="thumbnail">
                    <a href="#" onClick={handleDownload}>
                        <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
                    </a>
                </div>
                {/* <div className="content">
                    <h4 className="title"> 
                        <a href="#" onClick={handleDownload}>{portfolio.title}</a>
                    </h4>
                </div> */}
            </div>
        </>
    )
}

export default SpcialServicesPropOne;