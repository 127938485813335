import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import WorkOne from "../component/project/WorkOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import FooterOne from "../common/footer/FooterOne";



const Work = () => {
    return(
        <>
            <SEO title="Work" />
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BcrumbBannerOne
                    title="Our Work"
                    paragraph="We design and develop web and mobile applications for our clients worldwide."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-2.png"
                />


                <WorkOne colSize="col-xl-4 col-md-6" columnGap="row-15" />
                <CtaLayoutOne />
                <FooterOne />
            </main>
        </>
    )
}


export default Work;