import React from "react";
import { Link } from "react-router-dom";

const AboutFive = () => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/about-2.png"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-team">
              <div className="section-heading heading-left">
                <h2>Alone we can do so little, together we can do so much.</h2>
                <p>
                  {" "}
                  we're more than just a service provider – we're your digital
                  growth partner. Whether you're a small local business or a
                  large corporation, we have the skills, creativity, and
                  dedication to elevate your digital presence and drive your
                  business forward in the ever-evolving digital landscape.
                </p>
                <Link to="/teams" className="axil-btn btn-large btn-fill-primary">
                  Our Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
