import React from 'react';


const WorkPropOne = ({projectStyle, portfolio}) => {
    const openYoutubeVideo = (e) => {
        e.preventDefault();
        if (portfolio.youtubeLink) {
            window.open(portfolio.youtubeLink, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <>
            <div className={`project-grid ${projectStyle}`}>
                <div className="thumbnail">
                    <a href={portfolio.youtubeLink} onClick={openYoutubeVideo}>
                        <img src={process.env.PUBLIC_URL + portfolio.image} alt={portfolio.title} />
                    </a>
                </div>
                <div className="content">
                    <h4 className="title"> 
                        <a href={portfolio.youtubeLink} onClick={openYoutubeVideo}>{portfolio.title}</a>
                    </h4>
                    {/* <span className="subtitle">
                        {portfolio.category.map((cat, i) => (
                            <span key={i}>{cat}</span>
                        ))}
                    </span> */}
                </div>
            </div>
        </>
    )
}

export default WorkPropOne;