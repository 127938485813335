import React from 'react';
import { Link } from 'react-router-dom';
import TeamData from "../../data/team/TeamData.json";
import { slugify } from '../../utils';

const allData = TeamData;

const TeamOne = () => {
    return (
        <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
            <div className="container">
                <div className="section-heading heading-light">
                    <span className="subtitle">Our values</span>
                    <h2 className="title mb--50">Meet The Team</h2>
                </div>
                <div className="row">
                    {allData.map((data) => {
                        // Determine if the team member has an ID
                        const hasId = !!data.id;
                        return (
                            <div className="col-xl-3 col-sm-6" key={data.id || data.title}>
                                <div className="team-grid">
                                    <div className="thumbnail">
                                        {hasId ? (
                                            <Link to={process.env.PUBLIC_URL + `/teamsDetails/${slugify(data.title)}`}>
                                                <img
                                                    src={process.env.PUBLIC_URL + data.thumb}
                                                    alt={`Picture of ${data.title}`}
                                                    style={{ borderRadius: '50%', transition: 'all 0.5s ease-in-out' }}
                                                />
                                            </Link>
                                        ) : (
                                            <img
                                                src={process.env.PUBLIC_URL + data.thumb}
                                                alt={`Picture of ${data.title}`}
                                                style={{
                                                    borderRadius: '50%',
                                                    transition: 'all 0.5s ease-in-out',
                                                    cursor: 'pointer',
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.transform = 'rotate(360deg)';
                                                    e.currentTarget.style.borderRadius = '0';
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.transform = 'rotate(0deg)';
                                                    e.currentTarget.style.borderRadius = '50%';
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            {hasId ? (
                                                <Link to={process.env.PUBLIC_URL + `/teamsDetails/${slugify(data.title)}`}>
                                                    {data.title}
                                                </Link>
                                            ) : (
                                                <span>{data.title}</span>
                                            )}
                                        </h4>
                                        <span
                                            className="designation"
                                            dangerouslySetInnerHTML={{ __html: data.designation }}
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + '/images/others/circle-1.png'} alt="Circle decoration" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + '/images/others/line-3.png'} alt="Line decoration" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + '/images/others/bubble-5.png'} alt="Bubble decoration" />
                </li>
            </ul>
        </div>
    );
};

export default TeamOne;
