import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to="/">Home </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/teams"}>Teams</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/services"}>Service</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/special-services"}>
            Product
          </Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="#">
            Client Work
            <FaAngleDown />{" "}
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/project"}>Web Development</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/work"}>Videography</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/graphic-design"}>Graphic Design</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/seo"}>SEO</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/special-services"}>
                Product
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
