import React, { useState, useEffect } from "react";
import styled from "styled-components";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 900px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PopupImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const GraphicPropOne = ({ projectStyle, portfolio }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isPopupOpen]);

  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail" onClick={openPopup}>
          <img
            src={process.env.PUBLIC_URL + portfolio.image}
            alt="portfolio item"
          />
        </div>
        <div className="content">
          <h4 className="title"> {portfolio.title}</h4>
        </div>
      </div>

      {isPopupOpen && (
        <PopupOverlay onClick={closePopup}>
          <PopupContent onClick={(e) => e.stopPropagation()}>
            <PopupImage
              src={process.env.PUBLIC_URL + portfolio.image}
              alt="portfolio item"
            />
            <CloseButton onClick={closePopup}>×</CloseButton>
          </PopupContent>
        </PopupOverlay>
      )}
    </>
  );
};

export default GraphicPropOne;
