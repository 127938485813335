import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedin,
  FaInstagram,
  FaGoogle,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <Link to="https://www.facebook.com/people/DynoGrafx/100088087602308/?mibextid=LQQJ4d">
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to="https://www.google.com/search?client=mobilesearchapp&sca_esv=a5fe1f6bddd2655c&bih=797&biw=390&channel=iss&cs=1&hl=en_GB&rlz=1MDAPLA_en-GBIN1079IN1079&source=sh/x/loc/act/m1/5&v=315.0.630091404&sxsrf=ADLYWILtl0ZRIe5cPaLB5n2Mo6OVmuw4rg:1715320098685&q=Dynografx+Solutions+-+Best+Digital+marketing+%26+Web+Development+Nashik&ludocid=4753776749463711579&lsig=AB86z5Wm-25pIo2FV52OjDf4Ih4l&kgs=f6da8f8e8a165d03&shndl=30&shem=lrnole,lsp">
                  <FaGoogle />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/dynografx-solution/?trk=affiliated-pages">
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/dynografx/">
                  <FaInstagram />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <h6 className="text">
                    Address:
                  </h6>
                    <p className="d-block">
                      2nd Floor,Tirthraj apt,Near prakash petrol pump, Govind
                      nagar, Nashik, Nashik 422008
                    </p>
                  <h6 className="text">Email:</h6>
                  <a href="mailto:info@dynografx.com">info@dynografx.com</a>
                  <h6 className="text">Phone:</h6>
                  <a href="tel:919579808285">+91 957-980-8285</a>

                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/services`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Quick Links</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/"}>
                            Home
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/about-us"}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/teams"}
                          >
                            Our Teams
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/services"}
                          >
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/projects"}
                          >
                            Projects
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/contact"}
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            Contact
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            Terms of Use
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://dynografx.com/">Dynografx Solution</a>.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
