import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

// Home Pages Import
import Home from './pages/Home';

// Css Import
import './assets/scss/app.scss';
import AboutUs from './pages/AboutUs';
import Teams from './pages/Teams';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import TeamDetails from './pages/TeamsDetails';
import Work from './pages/Work';
import SpecialServices from './pages/Special-services';
import GraphicDesign from './pages/Graphic-design';
import SeoPage from './pages/Seo';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/services" element={<Services />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/special-services" element={<SpecialServices />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/teamsDetails/:slug" element={<TeamDetails />} />
          <Route path="/work" element={<Work />} />
          <Route path="/graphic-design" element={<GraphicDesign />} />
          <Route path="/seo" element={<SeoPage />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
