import React from "react"

import HeaderOne from "../common/header/HeaderOne"
import SEO from "../common/SEO"
import ColorSwitcher from "../elements/switcher/ColorSwitcher"
import FooterOne from "../common/footer/FooterOne"
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne"
import CounterUpTwo from "../component/counterup/CounterUpTwo"
import TeamOne from "../component/team/TeamOne"
// import VideoTwo from "../component/video/VideoTwo"
import CtaLayoutOne from "../component/cta/CtaLayoutOne"

const Teams = () => {
    return(
        <>
            <SEO title="Teams" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne/>
                <BcrumbBannerOne title="Meet Talented &amp; Dedicated Teams"
                    paragraph="Boost your brand's digital presence with beautifully crafted website &amp; mobile apps tailored to your unique needs."
                    styleClass="thumbnail-2"
                    mainThumb="/images/banner/banner-thumb-2.png"
                />

                <CounterUpTwo />
                <TeamOne />

                {/* <VideoTwo /> */}

                <CtaLayoutOne />





                <FooterOne parentClass='' />
            </main>
        </>
    )
}



export default Teams;