import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
  FaFax,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import {FaAngleDown} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/teams"}>Teams</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/services"}>Service</Link>
              </li>
              <li className="menu-item-has-children">
                <Link to="#">
                  Client Work
                  <FaAngleDown />{" "}
                </Link>
                <ul className="axil-submenu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/project"}>
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/work"}>
                      Videography
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "graphic-design"}>
                      Graphic Design
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/seo"}>SEO</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/special-services"}>
                      Product
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <Link to={process.env.PUBLIC_URL + "/project"}>Project</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/work"}>Client Work</Link>
              </li> */}
              <li>
                <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Contact Information</span>
                  <p>
                    Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York
                  </p>
                </address>
                <address className="address">
                  <span className="title">We're Available 24/7. Call Now.</span>
                  <a href="tel:8884562790" className="tel">
                    <FaPhone /> (888) 456-2790
                  </a>
                  <a href="tel:12125553333" className="tel">
                    <FaFax /> (121) 255-53333
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaXTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.behance.net/">
                        <FaBehance />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
