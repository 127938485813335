import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ProjectOne from "../component/project/ProjectOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import FooterOne from "../common/footer/FooterOne";



const Projects = () => {
    return(
        <>
            <SEO title="Projects" />
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BcrumbBannerOne
                    title="Our Project"
                    paragraph="We design and develop web and mobile applications for our clients worldwide."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-2.png"
                />


                <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" />
                <CtaLayoutOne />
                <FooterOne />
            </main>
        </>
    )
}


export default Projects;