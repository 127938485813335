import React, { useState, useEffect, useRef } from 'react';
import WorkPropOne from './itemProp/workPropOne';
import SectionTitle from '../../elements/section-title/SectionTitle';
import WorkData from "../../data/project/WorkData.json";

const filters = [
    { id: 1, label: "All Works" },
    { id: 2, label: "Testimonial" },
    { id: 3, label: "Podcast" },
    { id: 4, label: "Interior Shoot" },
    { id: 5, label: "Cinematic advertisements" },
    { id: 6, label: "Informative Video" },
    { id: 7, label: "Reels" },
    { id: 8, label: "Animation" },
    // Add more filters as needed
];

const AllData = WorkData;

const WorkOne = ({ parentClass, colSize, itemShow, columnGap }) => {
    const [getAllItems] = useState(AllData);
    const [visiableProject] = useState(itemShow ? itemShow : 15);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    const filterContainerRef = useRef(null);

    useEffect(() => {
        setActiveFilter(filters[0].label);
        setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        let target = e.target.textContent;

        setActiveFilter(target);

        let tempData = [];
        if (target === filters[0].label) {
            tempData = getAllItems.filter((data) => data.id <= visiableProject);
        } else {
            for (let i = 0; i < getAllItems.length; i++) {
                const element = getAllItems[i];
                let categories = element['category'];
                
                if (categories.includes(target)) {
                    tempData.push(element)
                } 
            }
        }
        setVisibleItems(tempData);
    };

    const scrollLeft = () => {
        if (filterContainerRef.current) {
            filterContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (filterContainerRef.current) {
            filterContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const styles = {
        filterContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '40px',
            backgroundColor: '#f5f5f5',
            padding: '10px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        },
        isotopeButton: {
            display: 'flex',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            scrollBehavior: 'smooth',
            padding: '10px 0',
        },
        scrollButton: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(0,0,0,0.6)',
            color: 'white',
            border: 'none',
            padding: '15px 10px',
            cursor: 'pointer',
            zIndex: 1,
            transition: 'background 0.3s ease',
            fontSize: '18px',
            fontWeight: 'bold',
        },
        scrollButtonLeft: {
            left: 0,
        },
        scrollButtonRight: {
            right: 0,
        },
        filterButton: {
            flex: '0 0 auto',
            marginRight: '15px',
            padding: '10px 20px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'all 0.3s ease',
            position: 'relative',
        },
        activeFilterButton: {
            color: '#007bff',
        },
        filterButtonAfter: {
            content: '""',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '2px',
            background: '#007bff',
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease',
        },
        activeFilterButtonAfter: {
            transform: 'scaleX(1)',
        }
    };

    return (
        <>
            <div className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
                <div className="container">
                    <SectionTitle 
                        subtitle="Our Project"
                        title="Some of our <br> finest work."
                        textAlignment="heading-left mb--40"
                        textColor=""
                    />
                    <div style={styles.filterContainer}>
                        <button 
                            style={{...styles.scrollButton, ...styles.scrollButtonLeft}} 
                            onClick={scrollLeft}
                            onMouseEnter={(e) => e.target.style.background = 'rgba(0,0,0,0.8)'}
                            onMouseLeave={(e) => e.target.style.background = 'rgba(0,0,0,0.6)'}
                        >&lt;</button>
                        <div style={styles.isotopeButton} ref={filterContainerRef}>
                            {filters.map((filter) => (
                                <button 
                                    onClick={handleChange} 
                                    style={{
                                        ...styles.filterButton,
                                        ...(filter.label === activeFilter ? styles.activeFilterButton : {})
                                    }}
                                    key={filter.id}
                                >
                                    {filter.label}
                                    <span style={{
                                        ...styles.filterButtonAfter,
                                        ...(filter.label === activeFilter ? styles.activeFilterButtonAfter : {})
                                    }}></span>
                                </button>
                            ))}
                        </div>
                        <button 
                            style={{...styles.scrollButton, ...styles.scrollButtonRight}} 
                            onClick={scrollRight}
                            onMouseEnter={(e) => e.target.style.background = 'rgba(0,0,0,0.8)'}
                            onMouseLeave={(e) => e.target.style.background = 'rgba(0,0,0,0.6)'}
                        >&gt;</button>
                    </div>
                    <div className={`row ${columnGap ? columnGap : "row-35"}`}>
                        {visibleItems.map((data) => (
                            <div className={colSize ? colSize : "col-md-6"} key={data.id}>
                                <WorkPropOne projectStyle="" portfolio={data}/>
                            </div>
                        ))}
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
            </div>
        </>
    )
}

export default WorkOne;